import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const PrivacyPage = () => (
    <Layout>
        <Seo title={'Privacy Policy | Nemo Personal Finance'} description={'To view the Nemo Personal Finance Data Protection Statement and understand what we will use your information for then please visit Nemo-loans.co.uk today.'} lang={'en-gb'} />
        <section className="oneCol blue cfx">
            <div className="bdr">
                <div className="bdrTop"></div>
                <div className="bdrMid cfx">
                    <div className="headingArea cfx">
                        <div className="bubble blue cfx cp">
                            <div className="inr">
                                <h1>Privacy Policy</h1>
                            </div>
                            <div className="bot"></div>
                        </div>
                        <div className="intro">
                            <p className="leading">PRIVACY POLICY</p>
                        </div>
                    </div>
                    <p>This privacy notice sets out how Nemo Personal Finance Limited (<strong>we</strong> or <strong>us</strong>) will collect and use
                    information about you. Please read it carefully to understand how we will use your personal
                    information. The terms of use of our website <a href="https://www.nemo-loans.co.uk/">www.nemo-loans.co.uk</a>  (<strong>our site</strong>) and our cookies
                    policy contain further details of how we will use your information.</p>
                    <p>If you have any questions or concerns in relation to this privacy notice, you can contact our Data
                    Protection Officer at:</p>
                    <p>Email: <a href="mailto:DPO@principality.co.uk">DPO@principality.co.uk</a><br />Post:&nbsp;Post: Nemo Data Protection Officer, Principality Building Society, PO Box 89, Principality
                    Buildings, Queen Street, Cardiff CF10 1UA</p>
                    <p>This privacy notice explains:</p>
                    <p>1.&nbsp;Information we may collect about you <br />2.&nbsp;How we will use your
                        information<br />3.&nbsp;Using your information to keep in touch with you <br />4.&nbsp;How we may
                            contact you <br />5.&nbsp;Who we may share your information with <br />6.&nbsp;Where we will
                                store your personal information <br />7. Our 'legal bases' for processing your
                                    information <br />8. Your rights in respect of your personal information <br />9. How
                                        and when we may change this privacy notice <br />10. How our website uses
                                            Cookies <br />11. Linking to other websites and their privacy polices<br /></p>
                      <p>
                        <strong>1.&nbsp;Information we may collect about you</strong></p>





                    <p>We collect information about you from a variety of sources, including:</p>
                    <p>a)&nbsp;information that you give to us:<br />b)&nbsp;information that we obtain from other
                        organisations; <br />c)&nbsp;information about your health and personal circumstances;
                            and <br />d)&nbsp;information that is automatically collected whenever you use our site.&nbsp;
                    </p>
                    <p>This section of our privacy notice explains more about the information we collect about you.</p>
                    <p>A: Information <strong><u>you give to us:</u></strong></p>
                    <p>
                    </p>
                    <table style={{'border': '1px solid #ce0a00', 'borderSpacing': '0', 'borderCollapse': 'collapse'}}>
                        <tbody>
                        <tr>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>When we collect it from you</span></strong></p>
                            </td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>What we collect</span></strong></p></td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>Why we collect it</span></strong></p></td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>How long we hold it for</span></strong></p>
                            </td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>More information</span></strong></p></td>
                        </tr>
                        <tr>
                            <td><p>When you opened an account with us, when you register on
                                our site or when you make a payment using our site.</p></td>
                            <td><p>We will usually ask you for your name, contact details
                                (address, e-mail address and phone number), date of birth and financial information
                                (including details of accounts held with us).&nbsp; We may ask you to provide
                                identification documents (such as a copy of your passport or driving licence).</p></td>
                            <td><p>To administer your accounts with us and provide you with an
                                effective service.&nbsp;</p><p><br />To comply with our regulatory obligations. <br /></p>
                            </td>
                            <td><p>We will generally hold this information for the duration of
                                your account with us and then for a period of 6 years after your account has been
                                closed.<br /></p><p><br />We may retain this information for an additional period where
                                required in connection with yours or our right to establish, commence or defend legal
                                claims.<br /></p></td>
                            <td><p>When you opened an account with us your contract contained
                                specific terms and conditions.&nbsp; Please ask us if you require another copy of these
                                terms and conditions.<br /></p><p><br />If you do not provide us with the information we
                                request, we may not be able to provide our services to you.<br /></p></td>
                        </tr>
                        <tr>
                            <td><p>When you fill in a form on our site or contact us by phone,
                                e-mail or otherwise.</p></td>
                            <td>Your name and contact details.</td>
                            <td><p>To deal with your enquiry and to administer your accounts
                                with us and provide you with an effective service.&nbsp; </p></td>
                            <td><p>We will generally hold this information for the duration of
                                your account with us and then for a period of 6 years after your account has been
                                closed.</p><p><br />We may retain this information for an additional period where required
                                in connection with yours or our right to establish, commence or defend legal claims.</p>
                            </td>
                            <td>If you do not provide us with this information, we may not be
                                able to deal appropriately with your enquiry.&nbsp; </td>
                        </tr>
                        <tr>
                            <td><p>If you choose to enter a competition, promotion or survey
                                that we promote.</p></td>
                            <td>Your name and contact details.</td>
                            <td><p>To administer the competition, promotion or survey. </p>
                            </td>
                            <td><p>This information is held for the duration of the
                                competition, promotion or survey and after for up to 6 years to monitor competition
                                terms.</p></td>
                            <td><p>You do not have to provide this information to us, but if
                                you do not do so, we cannot enter you into the competition or promotion.&nbsp; <br /></p>
                            </td>
                        </tr>
                        <tr>
                            <td>If you report a problem with our site.</td>
                            <td>Your name and contact details.</td>
                            <td><p>We collect this information in order to investigate the
                                problem and advise you of the outcome.</p></td>
                            <td><p>We will generally only hold this information for the time it takes to investigate and
                                resolve the problem.&nbsp; </p><p><br />Where the problem relates to your account we will
                                generally hold this information for the duration of your account with us and then for a
                                period of 6 years after your account has been closed.&nbsp;</p></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>When you contact us to discuss your personal circumstances, which could impact upon the way you 
                                engage with the society or management of your account and you request some form of support.<br/><br/>
                                We capture information regarding your personal situation, if we identify that you may require some 
                                form of support</td>
                            <td>We will collect information relating to your personal circumstances.<br/><br/>We have categorised 
                            the capture of information under the four headings of Health, Life Events, Capability and Resilience. 
                            The information we collect may be of a sensitive nature.</td>

                            <td><p>To make appropriate and meaningful adjustments to services in order to support circumstances you may 
                                find yourselves in. You only need to tell us once, meaning we can treat you with fairness and dignity 
                                throughout your time with us.</p><br/>
                                <p>To ensure that future interactions with the society are less challenging and more supportive.</p><br/>
                                <p>To meet our obligations.</p><br/>
                                <p>To help us design products and services which meet our customers needs.
                                </p></td>
                            <td><p>We will generally hold this information for as long as we have a legitimate purpose.</p><br/>
                            <p>We will generally review if a support is still adequate after 12 months.</p><br/>
                            <p>If the support is no longer required, the information will be deleted when you tell us, or 6 years after your account has been closed.</p>
                            </td>
                            <td><p>We will only capture this information if we have a legitimate purpose and can provide a support need</p><br/>
                            <p>You do not have to provide this information</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p>B: Information <strong><u>we receive directly from others:</u></strong></p>
                    <p>
                    </p>
                    <p>
                    </p>
                    <table>
                        <tbody>
                        <tr>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>Who we collect information from</span></strong>
                            </p></td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>What we collect</span></strong></p></td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>Why we collect it</span></strong></p></td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>How long we hold it for</span></strong></p>
                            </td>
                            <td><p><strong><span style={{ fontSize: '14pt' }}>More information</span></strong></p></td>
                        </tr>
                        <tr>
                            <td><p>From credit reference agencies when we considered your
                                application and then during the term of your mortgage.</p></td>
                            <td><p>Credit reference agencies may provide us with information
                                including about other debts you may have and any other addresses linked you to.</p></td>
                            <td><p>To administer your accounts with us and provide you with an
                                effective service.</p></td>
                            <td><p>We will generally hold this information for the duration of
                                your account with us and then for a period of 6 years after your account has been
                                closed, or an additional period in connection with legal claims.<br /></p></td>
                            <td><p>You can find out more about how we use credit reference
                                agencies in section 5, below. </p></td>
                        </tr>
                        <tr>
                            <td><p>From fraud prevention agencies when we considered your
                                application and then during the term of your loan.</p></td>
                            <td><p>Fraud prevention agencies may provide information about you
                                (such as your name, date of birth and current or previous addresses).</p></td>
                            <td><p>To administer your accounts with us and provide you with an
                                effective service.</p></td>
                            <td><p>We will generally hold this information for the duration of
                                your account with us and then for a period of 6 years after your account has been
                                closed, or an additional period in connection with legal claims.<br /></p></td>
                            <td><p>You can find out more about how we use fraud prevention
                                agencies in section 5, below. </p></td>
                        </tr>
                        <tr>
                            <td><p>As part of our management of your account, we may from time
                                to time collect information from third parties, such as other creditors, trustees, third
                                party representatives (e.g. the CAB).</p></td>
                            <td><p>Information about other charges, financial information
                                about you, and information about legal proceedings.</p></td>
                            <td><p>To administer your accounts with us and provide you with an
                                effective service.</p><p><br />To enable us to recover any debts we are owed.<br /></p></td>
                            <td><p>We will generally hold this information for the duration of
                                your account with us and then for a period of 6 years after your account has been
                                closed, or an additional period in connection with legal claims.&nbsp;<br /></p></td>
                            <td><p>&nbsp;</p></td>
                        </tr>
                        <tr>
                            <td><p>Occasionally, when we believe it is necessary to do so, we
                                may collect information about you which is available in the public domain (for example
                                information that is on Facebook or other social media sites).</p></td>
                            <td><p>The types of information we may collect includes employment
                                details, relationship status and verification of facts in relation to potential criminal
                                activity.</p></td>
                            <td><p>For the purposes of crime detection or prevention.</p><p>
                                <br />To enable us to recover any debts we are owed.<br /><br /></p></td>
                            <td><p>We will add this information to other information about you
                                and your account and we will generally hold it for the duration of your account with us
                                and then for a period of 6 years after your account has been closed.&nbsp;</p></td>
                            <td><p>&nbsp;</p></td>
                        </tr>
                        </tbody>
                    </table>
                    <p></p>
                    <p>C: Information about <strong><u>your health or personal circumstances:</u></strong></p>
                    <p>Your ability to make a sound financial decision or control your financial affairs may be
                        adversely affected by your health or personal circumstances (for instance if you suffer a
                        serious illness or a bereavement).&nbsp;Where we reasonably believe that this is the case, we
                        may record details of this, in order to provide you with any additional assistance you may
                        need.&nbsp; </p>
                    <p>We will only capture this information if we have the legitimate purpose of being able to provide
                    you with a support need and to ensure your economic well-being is protected. If we believe it
                    would be beneficial to capture your information, but we are unable to provide a support need,
                    we will ask for your permission to capture your circumstances. This information could be used
                    to help develop the support or service we provide in the future</p>
                    <p>If you change your mind about us holding the details, please contact
                        Customer Services to update your records. However, there may be times when we need to record
                        information about your health or personal circumstances to meet our regulatory obligations to 
                        identify vulnerable customers. We will only do so if we believe that this is in your best interests 
                        and to protect your economic wellbeing. More information about how we treat vulnerable customers 
                        can be found at: <a href="/support/extra-help-when-you-need-it">http://www.nemo-loans.co.uk/support/extra-help-when-you-need-it</a>.
                    </p>
                    <p>We understand that information of this nature is particularly sensitive and so we will ensure
                        that it is held securely in order to provide you with appropriate assistance.</p>
                    <p>D: Information we <strong><u>automatically collect</u></strong> when you visit our site: </p>
                    <ul>
                        <li>Technical information, including the Internet protocol (IP) address used to connect your
                            computer to the Internet, your login information, browser type and version, time zone
                            setting, browser plug-in types and versions, operating system and platform; and
                        </li>
                        <li>Information about your visit, including the full Uniform Resource Locators (URL) clickstream
                            to, through and from our site (including date and time); page response times, download
                            errors, length of visits to certain pages, page interaction information (such as scrolling,
                            clicks, and mouse-overs), and methods used to browse away from the page and any phone number
                            used to call our customer service number.
                        </li>
                    </ul>
                    <p>We use this automatically collected information to maintain and improve our services and our
                        site.</p>
                    <p>
                        <strong>2.&nbsp;How we will use your information</strong></p>
                    <p>We need to obtain, store and use information about you so that you can enjoy and benefit from our
                        service.&nbsp;We understand that as a customer (or former customer) of Nemo, you need to know
                        that we take your concerns about keeping your information safe seriously and only use it for
                        purposes that we have told you about.</p>
                    <p>We may use information we hold about you in the following ways:</p>
                    <p>
                        <u>To administer your accounts with us and provide you with an effective service:</u></p>
                    <ul>
                        <li>We may use your information to confirm your identity, update and correct our customer
                            records, notify you about changes to our service and checking your information with fraud
                            prevention agencies to help trace debtors, recover debt and prevent fraud or
                            money-laundering.
                        </li>
                        <li>We may carry out checks with fraud prevention agencies who may share your records with other
                            organisations to help them make decisions about credit applications and insurance proposals
                            and claims for you and your family, including motor, household, credit, life and other
                            insurance.
                        </li>
                        <li>We may use your information to ensure that content from our site is presented in the most
                            effective manner for you and for your computer and to allow you to participate in
                            interactive features of our service, when you choose to do so.
                        </li>
                    </ul>
                    <p>
                        <u>To comply with our regulatory obligations:</u></p>
                    <ul>
                        <li>We will use your information to help prevent financial crime and to meet legal and
                            regulatory compliance and reporting requirements.
                        </li>
                    </ul>
                    <p>
                        <u>To maintain and improve our service and our site:</u></p>
                    <ul>
                        <li>We will use your information to carry out statistical and market analyses, including
                            benchmarking exercises, to enable us to understand you better and improve our products and
                            services, to develop, test and improve our systems and to administer our site and for
                            internal operations, including troubleshooting, data analysis, testing, research,
                            statistical and survey purposes as part of our efforts to keep our site safe and secure.
                        </li>
                        <li>We may use your information for the purposes of staff training, and for analysing statistics
                            about credit, insurance and fraud together with the credit reference agencies and the fraud
                            prevention agencies.
                        </li>
                        <li>We may use your information to measure or understand the effectiveness of advertising we
                            serve to you and others and to make suggestions and recommendations to you and other users
                            of our site about goods or services that may interest you or them.
                        </li>
                    </ul>
                    <p>
                        <u>In order to keep in touch with you for marketing purposes:</u></p>
                    <ul>
                        <li>We may use your information to let you know about other relevant products and services, both
                            ours and those of other parties whose products and services we have agreed should be made
                            available to you (see the section below on 'Using your information to keep in touch with
                            you' for more information about this).
                        </li>
                    </ul>
                    <p>
                        <strong>3.&nbsp;Using your information to keep in touch with you</strong></p>
                    <p>We may use the information we hold about you in order to provide you with information about our
                        services that we think may interest you. </p>
                    <p>We will only contact you by electronic means (e-mail or SMS) with information about our services
                        if you have agreed to receive such information.&nbsp; </p>
                    <p>If you do not want us to use your data in this way, or to pass your details on to third parties
                        for marketing purposes, please tick the relevant box situated on the form on which we collect
                        your data. Alternatively, if you have changed your mind about being contacted for marketing
                        purposes, please contact Customer Services to update your preferences. </p>
                    <p>When we collect your information, we will also ask you whether you wish to receive information
                        about products and services from other companies that we think may be of interest to
                        you.&nbsp; We will only share your data with other companies for marketing purposes if you have
                        agreed to this.</p>
                    <p>
                        <strong>4.&nbsp;Contacting you</strong></p>
                    <p>We will use your contact information to send you important information via letters, emails, text
                        messages, or otherwise to telephone you about your account.&nbsp;We may also contact you with
                        optional account information, such as reminders to pay ahead of any date payment is due. If you
                        would like to change the method for receiving optional account information, or if you would like
                        to add or remove this service, just contact Customer Services. Please see our “Contact Us” page
                        for details how to contact us. </p>
                    <p>Please note that we may record telephone calls for security and training purposes. </p>
                    <p>
                        <u>Communicating via e-mail: Security Notice</u></p>
                    <p>If you do choose to send information by email you do so at your own risk.&nbsp; The majority of
                        email providers have adopted appropriate security controls to protect your information but we
                        cannot guarantee the security of your specific provider.</p>
                    <p>We will never ask you to send financial or sensitive information via email and will only ever ask
                        you to post or fax any documents back to us at our head office. We will only send you e-mails if
                        you ask us to, or if the only method we have for contacting you with important information is
                        via e-mail. For your protection, any e-mails we do send to you will contain censored or obscured
                        information.</p>
                    <p>If you choose to send us an e-mail, we will reply to you by e-mail where no other contact method
                        is available only after 24 hours have elapsed. If you are not comfortable with the security of
                        your provider and do not wish us to reply to you by e-mail, please contact us on 029 2020 7053
                        or 0800 1382935 within 24 hours to avoid receiving a response by e-mail.</p>
                    <p>Due to Nemo’s commitment to the security of your personal information, if your e-mail is from an
                        e-mail address that we do not recognise for you, one of our agents will contact you by telephone
                        on the numbers we hold to ensure the e-mail has come from you and to update your details with
                        us.</p>
                    <p>
                        <strong>5.&nbsp;Sharing your information</strong></p>
                    <p>Nemo Personal Finance Limited is a part of the Principality Group. We may share your personal
                        information with any other company that is part of the Principality Group.</p>
                    <p>We may share your information with selected third parties including:</p>
                    <ul>
                        <li>Our suppliers and sub-contractors for the performance of any contract we enter into with
                            you
                        </li>
                        <li>Analytics and search engine providers that assist us in the improvement and optimisation of
                            our site
                        </li>
                        <li>If we are unable to locate you to discuss any account you may hold with us, we may pass your
                            details to a third party in order to trace you
                        </li>
                        <li>Third parties we authorise such as a field representative, tracing agent, a debt collection
                            agent, or a solicitor, to act on our behalf in the collection of information and recovery of
                            debt
                        </li>
                        <li>Other third parties who also hold a charge on your property</li>
                        <li>HM Revenues and Customs or other authorities that require it</li>
                        <li>To any other parties connected with your account (including guarantors)</li>
                        <li>Credit reference agencies for the purpose of (i) confirming the payments you make under any
                            contract with us, and any default or failure to keep to its terms, and (ii) assessing your
                            credit score
                        </li>
                    </ul>
                    <p>
                        <strong>Credit reference agencies</strong></p>
                    <p>When we considered your application, and while you have an account, we will share your personal
                        information with credit reference agencies. We provide details of your lending and how you
                        manage your account, in order to check the details you have provided, assess your financial
                        position, help to prevent financial crime, protect our business and meet our legal obligations.
                        If an agency believes you are financially linked to another person or people (for example, by
                        having a joint account), this may be shown in your credit record and may be taken into account
                        in future applications made by you or the person or people.&nbsp; This could affect your ability
                        to get credit. This financial link will continue until one of you successfully asks for the link
                        to be removed.<br />If we use a credit reference agency in connection with you or other members of
                            your household, we may consider credit searches previously carried out on you and anyone you
                            are financially linked to. The information we provide to credit reference agencies may be
                            used in the same way by other organisations. The agency we use is Equifax. To find out what
                            information these agencies hold about you and how they use it, please contact them directly.
                            Their details can be found here <a
                                href="http://www.equifax.co.uk/crain/index">www.equifax.co.uk/crain/index</a>.<br /></p>
                    <p>
                        <strong>Preventing and detecting fraud</strong></p>
                    <p>We carry out checks for the purposes of preventing financial crime, including fraud and money
                        laundering, and to check your identity. For these checks we need to process personal information
                        about you. We share your personal information with a number of other financial-service providers
                        and fraud-prevention agencies. We may do this before or while providing services. We do this to
                        protect ourselves against fraud or other crime, to check your identity, to prevent fraud and
                        money laundering, and to keep to laws that apply to us. We may also carry out extra
                        fraud-prevention checks through fraud-prevention databases. We have the right to carry out these
                        checks under the agreement you have with us for our services.<br />If you give us false or
                            inaccurate information, or we discover any fraud, we will pass the details to
                            fraud-prevention agencies. Law-enforcement agencies (such as the police and the Crown
                            Prosecution Service) may gather and use this information to detect, investigate and prevent
                            crime. It is vital that you always give us accurate information. We and other organisations
                            may use your personal information to prevent fraud and money laundering when, for example
                    </p>
                    <ul>
                        <li>managing credit and credit-related accounts or services;</li>
                        <li>recovering debt;</li>
                        <li>checking details on proposals and claims for all types of insurance; and</li>
                        <li>checking details of job applicants and employees.</li>
                    </ul>
                    <p>Fraud-prevention agencies can hold your personal information for different periods of time. If
                        you are considered to pose a fraud or money-laundering risk, your information can be held for up
                        to six years. This may result in other organisations refusing to provide you with services or
                        employment. We and other organisations may use information recorded by fraud-prevention agencies
                        in other countries. Searches will be made for similar applications made to other lenders. All
                        applications will be passed to fraud-prevention agencies and if fraud is identified or
                        suspected, these details will be made available to all lenders involved. <br />If we, or a
                            fraud-prevention agency, believe that you pose a fraud or money-laundering risk, we may stop
                            providing existing services to you. If you would like more details on this, including
                            details of the fraud-prevention agencies and databases we currently use, please contact us.
                    </p>
                    <p>
                        <strong>Other information sharing</strong></p>
                    <p>Your information may be used by us and the other organisations listed above to help make
                        decisions about credit and credit related services and to trace debtors, recover debt, prevent
                        money laundering and fraud.</p>
                    <p>We may also share your personal information to third parties in the following circumstances:</p>
                    <ul>
                        <li>If we sell or buy any business or assets, in which case we may disclose your personal
                            data to the prospective seller or buyer of such business or assets
                        </li>
                        <li>If we or substantially all of our assets are acquired by a third party, in which case
                            personal data held by us about our customers will be one of the transferred assets
                        </li>
                        <li>If we are under a duty to disclose or share your personal data in order to comply with
                            any legal obligation, or in order to enforce or apply our terms of use and
                            other agreements; or to protect the rights, property, or safety of Nemo Personal Finance
                            Limited, our customers, or others. This includes exchanging information with other
                            companies and organisations for the purposes of fraud protection and credit risk
                            reduction
                        </li>
                    </ul>
                    <p>
                        <strong>6.&nbsp;Where we store your personal information </strong>
                    </p>
                    <p>All information you provide to us is stored on secure servers. Any payment transactions we
                        process will be encrypted using SSL technology. We do not store your information outside the
                        European Economic Area.</p>
                    <p>Unfortunately, the transmission of information via the internet is not completely secure.
                        Although we will do our best to protect your personal data, we cannot guarantee the security of
                        your data transmitted to our site. Once we have received your information, we will use strict
                        procedures and security features to try to prevent unauthorised access.</p>
                    <p>
                        <strong>7. Our 'legal bases' for processing your information </strong>
                    </p>
                    <p>Data-protection law sets out various lawful legal bases (or conditions) which allow us to process
                        your personal information:</p>
                    <ul>
                        <li>We will sometimes process your personal information based upon your permission. We will
                            always tell you when this is the case, and will ask you to agree before we process your
                            information under this condition.
                        </li>
                        <li>If you have a product with us, we need to process your personal information so that we
                            can meet our contractual obligations to you.
                        </li>
                        <li>We need to process your personal information to meet various legal and regulatory
                            obligations. These include anti-money laundering and fraud-prevention obligations, and
                            rules set by the Financial Conduct Authority.
                        </li>
                        <li>Sometimes we need to process your personal information for other reasons if we have a
                            reasonable or legitimate purpose that is not mentioned above or in your contract. This
                            may be when we need to process information to provide a product or service. For example,
                            we might do this to improve a service, or if we think you may reasonably expect us to
                            process your personal information. An example of this is when we share information with
                            credit-reference agencies to keep our business safe. This purpose is sometimes referred
                            to in law as legitimate interests for personal data processing. If your rights are at
                            risk of being undermined by us doing this, we won’t do it. We will only do this to run
                            and manage our business and to keep it safe.
                        </li>
                    </ul>
                    <p>We realise that some of these conditions will overlap and we may be able to rely on more than one
                        condition to justify our lawful reason for processing your personal information. </p>
                    <p>
                        <strong>8.&nbsp;Your rights</strong></p>
                    <p>You have the right to request from us access to your own personal information. This is sometimes
                        known as a 'subject access request'. We will respond to your subject access request free of
                        charge.</p>
                    <p>You also have the right to ask us not to process your personal data for direct marketing. We will
                        inform you (before collecting your data) if we intend to use your data for this purpose or if we
                        intend to disclose your information to any third party for this purpose. You can exercise your
                        right to prevent such processing by checking certain boxes on the forms we use to collect your
                        data or by contacting Customer Services.&nbsp;See the 'Using your information to keep in touch
                        with you' section of this privacy notice for more information.</p>
                    <p>From 25 May 2018, you will have additional rights to request from us:</p>
                    <ul>
                        <li>That any inaccurate information we hold about you is corrected</li>
                        <li>That your information is deleted</li>
                        <li>That we stop using your personal information for certain purposes</li>
                        <li>That your information is provided to you in a portable format</li>
                        <li>That decisions about you are not made by wholly automated means</li>
                    </ul>
                    <p>Many of the rights listed above are limited to certain defined circumstances and we may not be
                        able to comply with your request.</p>
                    <p>If you choose to make a request to us, we will aim to respond to you within one month.&nbsp;We
                        will not charge a fee for dealing with your request.&nbsp;Please see our “Contact Us” page for
                        details how to contact us.</p>
                    <p>If you are dissatisfied with how we are using your personal information or if you wish to
                        complain about how we have handled a request, then please contact our Data Protection Officer
                        and we will try to resolve any issues you may have.&nbsp; However, you also have the right to
                        complain to the Information Commissioner's Office, which is the statutory regulator for data
                        protection law.&nbsp;Details of how to complain to the ICO can be found at <a
                            href="https://ico.org.uk/concerns/">https://ico.org.uk/concerns/</a>.</p>
                    <p>
                        <strong>9.&nbsp;Changes to this privacy notice</strong></p>
                    <p>Any changes we may make to our privacy notice in the future will be posted on this page. We will
                        contact you to make you aware of any significant changes to this notice.</p>
                    <p>
                        <strong>10.&nbsp;How our website uses cookies </strong>
                    </p>
                    <p>Our site uses cookies to distinguish you from other users of our website. This helps us to
                        provide you with a good experience when you browse our website and also allows us to improve our
                        site. </p>
                    <p>For detailed information on the cookies we use and the purposes for which we use them see our
                        Cookie policy <a
                            href="/cookies">http://www.nemo-loans.co.uk/cookies</a>.
                    </p>
                    <p>
                        <strong>11.&nbsp;Other websites</strong></p>
                    <p>Our site contains links to other websites. This privacy policy only applies to this website and
                        how we collect and use your information. If you follow a link to any of these websites, please
                        note that these websites have their own privacy policies and that we do not accept any
                        responsibility or liability for these policies.&nbsp;Please check these policies before you
                        submit any personal data to these websites.<br /></p>
                    <ul>
                    </ul>
                </div>
                <div className="bdrBot"></div>
            </div>
        </section>
    </Layout>
)

export default PrivacyPage;